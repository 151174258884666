@import 'variables';

.body-card.body-signin,
.body-card.body-signup,
.body-card.body-password {
  .app-content {
    max-width: 400px;
  }
}

.body-card.body-share {
  .app-content {
    flex-direction: row;
    align-items: center;
    max-width: none;
  }
}


body.body-full-height {

  .app-scroll-content,
  .container-side-nav,
  .mat-sidenav-container,
  .mat-sidenav-content {
    height: 100%;
  }

  .mat-sidenav-content {
    display: flex;
    flex-direction: column;

    router-outlet + ng-component {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      min-height: 0;
      height: 100%;
    }
  }

  .mat-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;    
  }
}

.fs-tabs-sticky {
  width: 100vw !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 9 !important;
}

@media (max-width: $break-xsmall) {
  .body-sidenav {
    .mat-sidenav-content {
      padding-top: 0 !important;
    }

    .mat-toolbar {
      box-shadow: none !important;
    }

    .mat-tab-nav-bar, .mat-tab-header {
      border-bottom: 0 !important;
    }
  }
}
