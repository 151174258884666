@media print {

  @page {
    margin: 6.35mm;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  body,
  body app-root,
  body .app-container,
  body .app-content {
    height: auto !important;
  }

  body {
    background-color: #fff;
    zoom: 80%;

    .app-container  {
      padding: 0 !important;
      overflow: visible !important;
    }
  }

  .mat-sidenav,
  button,
  fs-menu,
  fs-filter .filter-search-container,
  fs-list .fs-list-actions,
  fs-list .fs-list-col-selection,
  fs-list fs-list-pagination,
  fs-chip .remove,
  .mat-tab-list .mat-tab-link:not(.mat-tab-label-active),
  .fs-nav-back,
  navbar,
  app-header,
  nav,
  .mat-select-arrow-wrapper,
  mat-select[fsselectbutton] {
    display: none !important;
  }

  fs-chip.fs-chip.removable {
    padding: 0px 12px;
    &.small {
      padding: 0 9px;
    }
  }

  fs-list .fs-list-col {
    padding: 3px !important;
  }

  .mat-sidenav-content {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-toolbar {
    background-color: #fff !important;
    box-shadow: none !important;
    color: inherit !important;
    padding: 0px !important;
  }

  .mat-card {
    box-shadow: none !important;
    padding: 0px !important;
  }

  a {
    color: inherit;
  }

  // table tr { page-break-inside: avoid; page-break-after: auto; }
  // table td { page-break-inside: avoid; page-break-after: auto; }
  // table thead { display: table-header-group; }
  // table tfoot { display: table-footer-group; }

  .avoid-page-break {
    page-break-inside: avoid;
    page-break-after: avoid;
  }

  // table tbody tr td:before,
  // table tbody tr td:after {
  //     content : "";
  //     height : 12px;
  //     display : block;
  // }
}

