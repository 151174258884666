@import 'variables';

html, body {
  font-family: $font-family,
}

a {
  color: $brand-link-color;
}

body {
  ::-webkit-scrollbar {
    width: $webkit-scrollbar-width;
  }

  ::-webkit-scrollbar-thumb {
    background: $webkit-scrollbar-color;
  }
}

app-root {
  display: block;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding-bottom: calc(constant(safe-area-inset-bottom)); /* iOS 11.0 */
  padding-bottom: calc(env(safe-area-inset-bottom)); /* iOS 11.2  */
}

.app-container {
  height: 100%;
  overflow-x: hidden;

  .app-content {
    margin: 0 auto;
    height: 100%;
  }

  > .ng-star-inserted {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.fs-scroll {
  max-height: 100%;
}

.two-column {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
}

@media (max-width: $break-small) {
  .two-column {
    flex-direction: column;
  }
}

@media (min-width: $break-small) {
  .two-column {
    > * {
      width: 50%;

      &:nth-child(n+2) {
        margin-left: 40px;
      }
    }
  }
}

.dark-logo,
.dark-logo:after {
  content: $brand-dark-logo;
}

.light-logo,
.light-logo:after {
  content: $brand-light-logo;
}

// https://stackoverflow.com/questions/37902708/google-charts-tooltip-flickering/37940389
svg > g > g.google-visualization-tooltip { pointer-events: none }
