@font-face {
  font-family: 'San Francisco Pro';
  src: local('San Francisco Pro Display Regular'), local('San-Francisco-Pro-Display-Regular'),
      url('/assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
      url('/assets/fonts/SFProDisplay-Regular.woff') format('woff'),
      url('/assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'San Francisco Pro';
  src: local('San Francisco Pro Display Semibold'), local('San-Francisco-Pro-Display-Semibold'),
      url('/assets/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
      url('/assets/fonts/SFProDisplay-Semibold.woff') format('woff'),
      url('/assets/fonts/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
