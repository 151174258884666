
@use 'variables' as fs-variables;
@use '@firestitch/style/variables' with (
  $palette: fs-variables.$primary-palette
);
@use '@firestitch/style/styles' as fs-style;
@use '@firestitch/tabs/styles' as fs-tabs;


@import 'dragula/dist/dragula.css';

@import '@firestitch/datepicker/styles';
@import '@firestitch/message/styles';
@import '@firestitch/skeleton/styles';
@import '@firestitch/file/styles';
@import '@firestitch/drawer/styles';
@import '@firestitch/selection/styles';
@import '@firestitch/html-editor/styles';
@import '@firestitch/diagram/styles';
@import '@firestitch/field-editor/styles';
@import '@firestitch/zoom-pan/styles';
@import '@firestitch/sidenav/styles';
@import '@firestitch/chip/styles';
@import '@firestitch/tab/styles';
@import '@firestitch/scrollbar/styles';
@import '@firestitch/dialog/styles';
@import '@firestitch/list/styles';
@import '@firestitch/form/styles';
@import '@firestitch/select/styles';
@import '@firestitch/tile/styles';
@import '@firestitch/pin/styles';
@import '@firestitch/tree/styles';
@import '@firestitch/popover/styles';
@import '@firestitch/autocomplete-chips/styles';
@import '@firestitch/filter/styles';
@import '@firestitch/gallery/styles';
@import '@firestitch/selectbutton/styles';

@import '@fullcalendar/common/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';

@include fs-style.core();
@include fs-file(fs-variables.$primary-palette);
@include fs-selection(fs-variables.$primary-palette);
@include fs-drawer(fs-variables.$primary-palette);
@include fs-field-editor(fs-variables.$primary-palette);
@include fs-tile(fs-variables.$primary-palette);
@include fs-form(fs-variables.$app-theme);
@include fs-tree(fs-variables.$primary-palette);
@include fs-html-editor(fs-variables.$primary-palette);
@include fs-autocomplete-chips(fs-variables.$primary-palette);
@include fs-popover(fs-variables.$primary-palette);


.fs-attribute-tag .fs-chip {
  font-size: 13px;
  min-height: auto !important;
  height: auto !important;
  padding: 3px 10px !important;
}

fs-badge {
  display: flex;
}

fs-drawer .drawer-container {
  .content-container .content {
    padding-left: 8px;
  }

  .side-container {
    .side-content {
      min-width: unset;
    }
  }
}

fs-list {
  .fs-list-row-group {
    background-color: rgba($brand-primary-color-rgb, .08);
    &:hover td {
      background-color: none !important;
    }
  }
}

.editor-container .ql-toolbar {
  padding: 12px 0;
}

@media (max-width: $break-xsmall) {
    .toast-container .toast {
      width: 100%;
    }
  }

@media (max-width: $break-xsmall) {
  .fs-dialog-mobile-mode-full {
    margin-top: 0 !important;
  }
}
