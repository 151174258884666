@charset "UTF-8";
@import 'dragula/dist/dragula.css';
@import '@fullcalendar/common/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 500 calc(15px * 0.83) / 24px "San Francisco Pro", "Helvetica", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 500 calc(15px * 0.67) / 24px "San Francisco Pro", "Helvetica", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 500 15px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 500 15px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-card {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 15px;
}

.mat-checkbox {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 15px;
}

.mat-calendar {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 500;
}

.mat-dialog-title {
  font: 500 20px / 32px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 500 15px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 15px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-select {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-step-label {
  font-size: 15px;
  font-weight: 500;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-list-option {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 15px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 15px;
}
.mat-list-base .mat-subheader {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 15px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 500;
  font-size: 15px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 500 calc(15px * 0.83) / 24px "San Francisco Pro", "Helvetica", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 500 calc(15px * 0.67) / 24px "San Francisco Pro", "Helvetica", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 500 15px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 500 15px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-card {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 15px;
}

.mat-checkbox {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 15px;
}

.mat-calendar {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 500;
}

.mat-dialog-title {
  font: 500 20px / 32px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 500 15px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 15px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-select {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-step-label {
  font-size: 15px;
  font-weight: 500;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-list-option {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 15px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 15px;
}
.mat-list-base .mat-subheader {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "San Francisco Pro", "Helvetica", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
  font-size: 15px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 500;
  font-size: 15px;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--brand-primary-color);
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--brand-primary-color);
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--brand-primary-color);
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--brand-primary-color);
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: var(--brand-primary-color);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: var(--brand-primary-color);
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: var(--brand-primary-color);
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: var(--brand-primary-color);
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: var(--brand-primary-color);
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: var(--brand-primary-color);
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: var(--brand-primary-color);
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: var(--brand-primary-color);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--brand-primary-color);
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--brand-primary-color);
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: var(--brand-primary-color);
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--brand-primary-color);
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: var(--brand-primary-color);
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: var(--brand-primary-color);
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: var(--brand-primary-color);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--brand-primary-color) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--brand-primary-color) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: var(--brand-primary-color);
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--brand-primary-color);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--brand-primary-color);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: var(--brand-primary-color);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--brand-primary-color) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--brand-primary-color) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--brand-primary-color);
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--brand-primary-color);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--brand-primary-color);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: var(--brand-primary-color);
}
.mat-datepicker-toggle-active.mat-accent {
  color: var(--brand-primary-color);
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--brand-primary-color);
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: var(--brand-primary-color);
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: var(--brand-primary-color);
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--brand-primary-color);
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: var(--brand-primary-color);
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: var(--brand-primary-color);
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: var(--brand-primary-color);
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--brand-primary-color);
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: var(--brand-primary-color);
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: var(--brand-primary-color);
}
.mat-icon.mat-accent {
  color: var(--brand-primary-color);
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: var(--brand-primary-color);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: var(--brand-primary-color);
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: var(--brand-primary-color);
}

.mat-progress-bar-buffer {
  background-color: var(--brand-primary-color);
}

.mat-progress-bar-fill::after {
  background-color: var(--brand-primary-color);
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: var(--brand-primary-color);
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: var(--brand-primary-color);
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: var(--brand-primary-color);
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--brand-primary-color);
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: var(--brand-primary-color);
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--brand-primary-color);
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: var(--brand-primary-color);
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--brand-primary-color);
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--brand-primary-color);
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--brand-primary-color);
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: var(--brand-primary-color);
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--brand-primary-color);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--brand-primary-color);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: var(--brand-primary-color);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--brand-primary-color);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--brand-primary-color);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: var(--brand-primary-color);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: var(--brand-primary-color);
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: var(--brand-primary-color);
  opacity: 0.2;
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: var(--brand-primary-color);
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: var(--brand-primary-color);
  opacity: 0.2;
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--brand-primary-color);
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: var(--brand-primary-color);
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--brand-primary-color);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--brand-primary-color);
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--brand-primary-color);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: var(--brand-primary-color);
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--brand-primary-color);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: var(--brand-primary-color);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--brand-primary-color);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: var(--brand-primary-color);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: var(--brand-primary-color);
  color: white;
}
.mat-toolbar.mat-accent {
  background: var(--brand-primary-color);
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: var(--brand-primary-color);
}

.fs-delimit > *:not(:last-child):after {
  content: ", ";
}

.fs-tabs-vertical .mat-dialog-content {
  margin-bottom: -24px;
}

@media (max-width: 599px) {
  .fs-tabs-vertical .buttons button {
    width: 100%;
    display: block;
  }
}
@media (min-width: 599px) {
  .fs-tabs-vertical > .mat-tab-header, .fs-tabs-vertical.mat-tab-header {
    border: none;
    margin-right: 40px;
  }

  .fs-tabs-vertical > .mat-tab-header .mat-ink-bar, .fs-tabs-vertical.mat-tab-header .mat-ink-bar {
    display: none;
  }

  .fs-tabs-vertical > .mat-tab-header .mat-tab-label,
.fs-tabs-vertical > .mat-tab-header > .mat-tab-link-container .mat-tab-link, .fs-tabs-vertical.mat-tab-header .mat-tab-label,
.fs-tabs-vertical.mat-tab-header > .mat-tab-link-container .mat-tab-link {
    text-transform: initial;
    font-size: 109%;
    justify-content: start;
    min-width: auto;
    height: 38px;
    opacity: 1;
  }

  .fs-tabs-vertical > .mat-tab-header .mat-tab-label.mat-tab-label-active,
.fs-tabs-vertical > .mat-tab-header > .mat-tab-link-container .mat-tab-link.mat-tab-label-active, .fs-tabs-vertical.mat-tab-header .mat-tab-label.mat-tab-label-active,
.fs-tabs-vertical.mat-tab-header > .mat-tab-link-container .mat-tab-link.mat-tab-label-active {
    background-color: var(--brand-primary-color);
    color: #fff;
    border-radius: 4px;
  }

  .fs-tabs-vertical > .mat-tab-header .mat-tab-labels, .fs-tabs-vertical.mat-tab-header .mat-tab-labels {
    flex-direction: column;
  }

  .fs-tabs-vertical > .mat-tab-header.mat-tab-nav-bar, .fs-tabs-vertical.mat-tab-header.mat-tab-nav-bar {
    margin: 0;
    border: 0;
    width: auto;
    margin-right: 40px;
  }

  .fs-tabs-vertical > .mat-tab-header.mat-tab-nav-bar .mat-tab-links, .fs-tabs-vertical.mat-tab-header.mat-tab-nav-bar .mat-tab-links {
    flex-direction: column;
    display: inline-flex;
  }

  .fs-tabs-vertical.mat-tab-group {
    flex-direction: row;
  }

  .fs-tabs-vertical.mat-tab-group > .mat-tab-body-wrapper {
    width: 100%;
  }
}
.fs-tabs-sticky {
  position: sticky !important;
  top: 0;
  z-index: 2;
  background-color: #fff;
}

.fs-tabs-sticky .mat-tab-link, .fs-tabs-sticky .mat-tab-label {
  height: 35px;
}

.fs-tabs-sticky .mat-tab-label-container {
  overflow: hidden;
}

.fs-tabs-sticky .mat-tab-link-container {
  overflow: hidden;
}

.fs-tabs-sticky .mat-tab-link, .fs-tabs-sticky .mat-tab-label {
  min-width: unset;
  padding: 0 10px;
}

.mat-tab-nav-bar:not(.fs-tabs-vertical) > .mat-tab-header .mat-tab-label.cdk-focused, .mat-tab-nav-bar:not(.fs-tabs-vertical) > .mat-tab-header .mat-tab-label:hover,
.mat-tab-nav-bar:not(.fs-tabs-vertical) > .mat-tab-header .mat-tab-link.cdk-focused,
.mat-tab-nav-bar:not(.fs-tabs-vertical) > .mat-tab-header .mat-tab-link:hover,
.mat-tab-nav-bar:not(.fs-tabs-vertical) > .mat-tab-link-container .mat-tab-label.cdk-focused,
.mat-tab-nav-bar:not(.fs-tabs-vertical) > .mat-tab-link-container .mat-tab-label:hover,
.mat-tab-nav-bar:not(.fs-tabs-vertical) > .mat-tab-link-container .mat-tab-link.cdk-focused,
.mat-tab-nav-bar:not(.fs-tabs-vertical) > .mat-tab-link-container .mat-tab-link:hover,
.mat-tab-group:not(.fs-tabs-vertical) > .mat-tab-header .mat-tab-label.cdk-focused,
.mat-tab-group:not(.fs-tabs-vertical) > .mat-tab-header .mat-tab-label:hover,
.mat-tab-group:not(.fs-tabs-vertical) > .mat-tab-header .mat-tab-link.cdk-focused,
.mat-tab-group:not(.fs-tabs-vertical) > .mat-tab-header .mat-tab-link:hover,
.mat-tab-group:not(.fs-tabs-vertical) > .mat-tab-link-container .mat-tab-label.cdk-focused,
.mat-tab-group:not(.fs-tabs-vertical) > .mat-tab-link-container .mat-tab-label:hover,
.mat-tab-group:not(.fs-tabs-vertical) > .mat-tab-link-container .mat-tab-link.cdk-focused,
.mat-tab-group:not(.fs-tabs-vertical) > .mat-tab-link-container .mat-tab-link:hover {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: rgba(var(--brand-primary-color-rgb), 0.2);
}

.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-header .mat-tab-label.cdk-focused.mat-tab-label-active,
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-header .mat-tab-link.cdk-focused.mat-tab-label-active,
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-link-container .mat-tab-label.cdk-focused.mat-tab-label-active,
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-link-container .mat-tab-link.cdk-focused.mat-tab-label-active,
.mat-tab-group.fs-tabs-vertical > .mat-tab-header .mat-tab-label.cdk-focused.mat-tab-label-active,
.mat-tab-group.fs-tabs-vertical > .mat-tab-header .mat-tab-link.cdk-focused.mat-tab-label-active,
.mat-tab-group.fs-tabs-vertical > .mat-tab-link-container .mat-tab-label.cdk-focused.mat-tab-label-active,
.mat-tab-group.fs-tabs-vertical > .mat-tab-link-container .mat-tab-link.cdk-focused.mat-tab-label-active {
  background-color: rgba(var(--brand-primary-color-rgb));
}

.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-header .mat-tab-label.cdk-focused:not(.mat-tab-label-active), .mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-header .mat-tab-label:hover:not(.mat-tab-label-active),
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-header .mat-tab-link.cdk-focused:not(.mat-tab-label-active),
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-header .mat-tab-link:hover:not(.mat-tab-label-active),
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-link-container .mat-tab-label.cdk-focused:not(.mat-tab-label-active),
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-link-container .mat-tab-label:hover:not(.mat-tab-label-active),
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-link-container .mat-tab-link.cdk-focused:not(.mat-tab-label-active),
.mat-tab-nav-bar.fs-tabs-vertical > .mat-tab-link-container .mat-tab-link:hover:not(.mat-tab-label-active),
.mat-tab-group.fs-tabs-vertical > .mat-tab-header .mat-tab-label.cdk-focused:not(.mat-tab-label-active),
.mat-tab-group.fs-tabs-vertical > .mat-tab-header .mat-tab-label:hover:not(.mat-tab-label-active),
.mat-tab-group.fs-tabs-vertical > .mat-tab-header .mat-tab-link.cdk-focused:not(.mat-tab-label-active),
.mat-tab-group.fs-tabs-vertical > .mat-tab-header .mat-tab-link:hover:not(.mat-tab-label-active),
.mat-tab-group.fs-tabs-vertical > .mat-tab-link-container .mat-tab-label.cdk-focused:not(.mat-tab-label-active),
.mat-tab-group.fs-tabs-vertical > .mat-tab-link-container .mat-tab-label:hover:not(.mat-tab-label-active),
.mat-tab-group.fs-tabs-vertical > .mat-tab-link-container .mat-tab-link.cdk-focused:not(.mat-tab-label-active),
.mat-tab-group.fs-tabs-vertical > .mat-tab-link-container .mat-tab-link:hover:not(.mat-tab-label-active) {
  border-radius: 4px;
  background-color: rgba(var(--brand-primary-color-rgb), 0.2);
}

.fs-delimit > *:not(:last-child):after {
  content: ", ";
}

.fs-date-picker-calendar .highlight:not(.highlight-from):not(.highlight-to) {
  background-color: rgba(var(--brand-primary-color-rgb), 0.2);
}
.fs-date-picker-calendar .highlight.highlight-from {
  background: linear-gradient(90deg, #fff 0%, #fff 49%, rgba(var(--brand-primary-color-rgb), 0.2) 51%);
}
.fs-date-picker-calendar .highlight.highlight-to {
  background: linear-gradient(-90deg, #fff 0%, #fff 49%, rgba(var(--brand-primary-color-rgb), 0.2) 51%);
}
.fs-date-picker-calendar .period-mode {
  cursor: default;
  pointer-events: none;
  background: none;
}
.fs-date-picker-calendar .period {
  width: 48px;
  padding: 14px 0;
  font-size: 14px;
  text-align: center;
  vertical-align: top;
  background: #e4e4e4;
  color: #858585;
  cursor: pointer;
}
.fs-date-picker-calendar .period:hover {
  background: #effaff;
}
.fs-date-picker-calendar .month-range:not(.range-selected) .tile-content.range-from {
  background-color: #fff;
  color: #000;
  font-weight: normal;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 8px solid transparent;
}
.fs-date-picker-calendar .month-range:not(.range-selected) .tile-content.range-from:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid transparent;
  border-radius: 100%;
  border-left-color: rgba(var(--brand-primary-color-rgb), 1);
  border-bottom-color: rgba(var(--brand-primary-color-rgb), 1);
  transform: rotateZ(45deg);
}
.fs-date-picker-calendar .week.hover td:not(.selected).period {
  background: rgba(var(--brand-primary-color-rgb), 0.1);
}
.fs-date-picker-calendar .week.hover td:not(.selected).day {
  background: rgba(var(--brand-primary-color-rgb), 0.3) !important;
}

.fs-date-picker-dialog-shadow {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.fs-date-picker-dialog {
  display: flex;
  justify-content: center;
}

.fs-date-picker-container.date-mode-year .calendar-view, .fs-date-picker-container.date-mode-month .calendar-view {
  height: 350px;
}
.fs-date-picker-container .buttons {
  background: #fff;
  display: flex;
  position: relative;
}
.fs-date-picker-container .buttons button {
  margin: 5px;
  width: 100%;
}
.fs-date-picker-container * {
  user-select: none;
}
.fs-date-picker-container:focus {
  outline: none;
}
.fs-date-picker-container:active {
  outline: none;
}
.fs-date-picker-container .calendar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0 !important;
  overflow: hidden;
}
.fs-date-picker-container .calendar-container:focus {
  outline: none;
}
.fs-date-picker-container .calendar-container:active {
  outline: none;
}
.fs-date-picker-container .calendar-container::-webkit-scrollbar {
  display: none;
}
.fs-date-picker-container .inline-date {
  padding: 10px;
  width: 100%;
}
.fs-date-picker-container .tile {
  padding: 0;
}
.fs-date-picker-container .tile-content {
  outline: none;
  display: flex;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  border-radius: 50%;
  flex-direction: column;
  clip-path: circle(46%);
  box-sizing: border-box;
}
.fs-date-picker-container .tile-content.enabled {
  background: rgba(var(--brand-primary-color-rgb), 0.1);
  font-weight: bold;
  color: rgba(var(--brand-primary-color-rgb), 1);
}
.fs-date-picker-container .tile-content.surrounding {
  color: #b2b2b2;
}
.fs-date-picker-container .tile-content.disabled {
  background: #f9f9f9;
  color: #ccc;
  cursor: initial;
  pointer-events: none;
}
.fs-date-picker-container .tile-content.selected, .fs-date-picker-container .tile-content.range-from, .fs-date-picker-container .tile-content.range-to {
  background-color: var(--brand-primary-color);
  font-weight: bold;
  color: #fff;
}
.fs-date-picker-container .tile-content:hover:not(.disabled):not(.selected):not(.range-from):not(.range-to) {
  background: rgba(var(--brand-primary-color-rgb), 0.3);
}
.fs-date-picker-container .tile-content.now:not(:hover)::after {
  content: "•";
  font-size: 140%;
  line-height: 0;
  margin-top: 0;
  top: 5px;
  position: relative;
  color: rgba(var(--brand-primary-color-rgb), 1);
}
.fs-date-picker-container .calendar-container {
  width: 100%;
  position: relative;
  flex: 1 1 45%;
  overflow: hidden;
}
.fs-date-picker-container .clear {
  clear: both;
}
.fs-date-picker-container .close {
  padding: 5px;
  display: none;
}
.fs-date-picker-container fs-tabnav {
  display: none;
}
.fs-date-picker-container fs-tabnav .md-tabs a {
  width: 50%;
}
.fs-date-picker-container table {
  border-spacing: 0;
  margin: auto;
}

.fs-date-picker-dialog .has-view-time .summary .date.active,
.fs-date-picker-dialog .has-view-time .summary .time.active {
  color: var(--brand-primary-color);
}

.time-container .minutes .button-expand {
  color: var(--brand-primary-color);
}

.mat-form-field.fs-date-picker-datetime-range-field {
  min-width: 355px;
}

.mat-form-field.fs-date-picker-date-range-field {
  min-width: 220px;
}

.mat-form-field .mat-form-field-flex.hide-clear .fs-clear-wrapper {
  display: none;
}

fs-date-picker-dialog * {
  touch-action: manipulation;
}

.fs-date-picker-prevent-pull-to-refresh {
  overscroll-behavior: none;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-top-right,
.toast-bottom-right {
  justify-content: flex-end;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.toast-container.toast-top-center .ngx-toastr, .toast-container.toast-bottom-center .ngx-toastr,
.toast-container .ngx-toastr {
  width: 400px;
}
.toast-container .mat-toast-content {
  display: flex;
  align-items: center;
}
.toast-container .mat-icon {
  vertical-align: middle;
  margin: 0 10px 0 0;
  color: #fff;
}
.toast-container .ngx-toastr {
  padding: 15px 15px 15px 25px;
  background-color: #030303;
  pointer-events: auto;
  box-shadow: 0 0 5px #999999;
}
.toast-container .ngx-toastr.toast-info {
  background-image: none !important;
  background-color: #29B7FF;
}
.toast-container .ngx-toastr.toast-error {
  background-image: none !important;
  background-color: #FA7567;
}
.toast-container .ngx-toastr.toast-success {
  background-image: none !important;
  background-color: #34BD43;
}
.toast-container .ngx-toastr.toast-warning {
  background-image: none !important;
  background-color: #F2C91B;
}

.fs-message-banner {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  max-width: 100%;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.fs-message-banner {
  display: flex;
  align-self: start;
}
.fs-message-banner .message {
  color: #fff;
  width: 100%;
}
.fs-message-banner mat-icon {
  color: #fff;
  margin-right: 10px;
}
.fs-message-banner:nth-child(1n+2) {
  margin-top: 5px;
}
.fs-message-banner.fs-message-success {
  background-color: #34BD43;
}
.fs-message-banner.fs-message-error {
  background-color: #FA7567;
}
.fs-message-banner.fs-message-info {
  background-color: #29B7FF;
}
.fs-message-banner.fs-message-warning {
  background-color: #F2C91B;
}

fs-message {
  display: block;
}

fs-messages fs-message {
  margin-bottom: 5px;
}
fs-messages fs-message:last-child {
  margin-bottom: 0;
}

fs-message-dialog > div {
  min-width: 240px;
}
fs-message-dialog mat-icon {
  margin-right: 10px;
}
fs-message-dialog .fs-message-success .mat-icon {
  color: #34BD43;
}
fs-message-dialog .fs-message-error .mat-icon {
  color: #FA7567;
}
fs-message-dialog .fs-message-info .mat-icon {
  color: #29B7FF;
}
fs-message-dialog .fs-message-warning .mat-icon {
  color: #F2C91B;
}

.toast-bottom-right, .toast-bottom-left {
  bottom: 0;
}

@media (max-width: 599px) {
  .toast-container {
    top: 0px;
    left: 0;
    right: 0;
    bottom: unset;
    width: 100%;
    padding: 15px;
    padding-top: max(env(safe-area-inset-top), 15px);
    box-sizing: border-box;
  }
  .toast-container .ngx-toastr {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
.fs-skeleton-line,
.fs-skeleton-button,
.fs-skeleton-avatar {
  height: 6px;
  left: 0;
  right: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: rgba(246, 247, 249, 0.5);
  background-image: linear-gradient(to right, rgba(246, 247, 249, 0) 0, rgba(233, 235, 238, 0.8) 20%, rgba(246, 247, 249, 0) 40%, rgba(246, 247, 249, 0.05) 100%);
  background-repeat: no-repeat;
  background-size: 1000px;
  position: relative;
  max-width: 100%;
  border-radius: 2px;
}

.fs-skeleton-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.fs-skeleton-line {
  width: 400px;
  height: 17px;
  margin-bottom: 17px;
}

.fs-skeleton-button {
  width: 100px;
  height: 35px;
  border-radius: 3px;
}

.fs-skeleton-placeholder {
  height: 17px;
  background: #e6e6e6;
  border-radius: 2px;
  max-width: 550px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.fs-skeleton-hide {
  display: none !important;
}

.fs-skeleton-banner {
  display: flex;
}
.fs-skeleton-banner .fs-skeleton-avatar {
  margin-right: 10px;
}
.fs-skeleton-banner .skeleton-lines {
  display: flex;
  flex: 1;
  align-content: space-between;
  flex-direction: column;
  justify-content: center;
}

.fs-skeleton-content .fs-skeleton-line:last-child {
  margin-bottom: 0;
}

.fs-skeleton-container {
  width: 400px;
  max-width: 100%;
}

.cdk-overlay-backdrop.fs-drawer-backdrop {
  pointer-events: none;
  transform: none;
}
.cdk-overlay-backdrop.fs-drawer-backdrop.fs-drawer-backdrop-active {
  background: rgba(0, 0, 0, 0.32);
}

body.fs-drawer-open::-webkit-scrollbar-thumb,
body.fs-drawer-open::-webkit-scrollbar {
  background: transparent;
  visibility: hidden;
}

.selection-dialog-opened {
  padding-bottom: 70px;
  box-sizing: border-box;
}

fs-html-editor {
  position: relative;
  display: block;
}
fs-html-editor.focused .bottom-line {
  opacity: 1;
  display: block;
  transform: scaleX(1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.1s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

fs-html-renderer .fr-view > *:first-child {
  margin-top: 0;
}
fs-html-renderer .fr-view > *:last-child {
  margin-bottom: 0;
}

.fs-html-editor-default {
  position: relative;
}
.fs-html-editor-default p {
  margin: 10px 0;
}
.fs-html-editor-default hr {
  border: none;
  height: 1px;
  background-color: #d5d5d5;
}
.fs-html-editor-default table {
  border: none;
  border-collapse: collapse;
  empty-cells: show;
  max-width: 100%;
}
.fs-html-editor-default table th, .fs-html-editor-default table td {
  padding: 5px;
  border: 1px solid #DDD;
  min-width: 5px;
}
.fs-html-editor-default table th {
  background: #ececec;
  text-align: center;
}
.fs-html-editor-default blockquote {
  border-left: 3px solid #ccc;
  margin: 5px 0;
  padding: 6px 16px;
  color: #6b6b6b;
}
.fs-html-editor-default p.code {
  font-family: monospace;
  background: #eaeaea;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
}
.fs-html-editor-default img {
  max-width: 100%;
}
.fs-html-editor-default li {
  margin: 5px 0;
  margin-left: 40px;
  display: block;
}
.fs-html-editor-default li::before {
  content: " ";
  position: absolute;
  display: list-item;
  white-space: nowrap;
}
.fs-html-editor-default ol, .fs-html-editor-default ul {
  padding: 0;
}
.fs-html-editor-default ol {
  list-style-type: decimal;
}
.fs-html-editor-default ol ol {
  list-style-type: lower-alpha;
}
.fs-html-editor-default ol ol ol {
  list-style-type: lower-roman;
}
.fs-html-editor-default ol ol ol ol {
  list-style-type: decimal;
}
.fs-html-editor-default ol ol ol ol ol {
  list-style-type: lower-alpha;
}
.fs-html-editor-default ol ol ol ol ol ol {
  list-style-type: lower-roman;
}
.fs-html-editor-default ul.checklist {
  list-style: none;
}
.fs-html-editor-default ul.checklist ul {
  list-style: none;
}
.fs-html-editor-default ul.checklist li:before {
  content: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 408 408" version="1.1" viewBox="0 0 408 408" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="M357,0H51C22.95,0,0,22.95,0,51v306c0,28.05,22.95,51,51,51h306c28.05,0,51-22.95,51-51V51C408,22.95,385.05,0,357,0z M357,357H51V51h306V357z"/></svg>');
  cursor: pointer;
  height: 1em;
  margin-left: -1.5em;
  margin-top: 0.125em;
  position: absolute;
  width: 1em;
}
.fs-html-editor-default ul.checklist li.checked {
  text-decoration: line-through;
}
.fs-html-editor-default ul.checklist li.checked:before {
  content: url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 459 459" style="enable-background:new 0 0 459 459;" xml:space="preserve"><g><g id="check-box-outline"><path d="M124.95,181.05l-35.7,35.7L204,331.5l255-255l-35.7-35.7L204,260.1L124.95,181.05z M408,408H51V51h255V0H51C22.95,0,0,22.95,0,51v357c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V204h-51V408z"/></g></g></svg>');
}
.fs-html-editor-default blockquote,
.fs-html-editor-default blockquote blockquote {
  background: #f9f9f9;
}
.fs-html-editor-default blockquote p,
.fs-html-editor-default blockquote blockquote p {
  display: inline;
}
.fs-html-editor-default video {
  width: 100%;
}

.fs-diagram {
  position: relative;
  display: block;
  user-select: none;
}

.fs-diagram-object {
  position: absolute;
  cursor: pointer;
  z-index: 8;
  user-select: text;
}
.fs-diagram-object .fs-diagram-source {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #cfcfcf;
  border-radius: 50%;
  display: none;
  cursor: pointer;
}
.fs-diagram-object .fs-diagram-source.north, .fs-diagram-object .fs-diagram-source.north-east, .fs-diagram-object .fs-diagram-source.north-west {
  top: -5px;
}
.fs-diagram-object .fs-diagram-source.east, .fs-diagram-object .fs-diagram-source.west {
  bottom: calc(50% - 5px);
}
.fs-diagram-object .fs-diagram-source.north, .fs-diagram-object .fs-diagram-source.south {
  left: calc(50% - 5px);
}
.fs-diagram-object .fs-diagram-source.east, .fs-diagram-object .fs-diagram-source.north-east, .fs-diagram-object .fs-diagram-source.south-east {
  right: -5px;
}
.fs-diagram-object .fs-diagram-source.west, .fs-diagram-object .fs-diagram-source.north-west, .fs-diagram-object .fs-diagram-source.south-west {
  left: -5px;
}
.fs-diagram-object .fs-diagram-source.south, .fs-diagram-object .fs-diagram-source.south-east, .fs-diagram-object .fs-diagram-source.south-west {
  bottom: -5px;
}
.fs-diagram-object:hover:not(.jtk-drag):not(.jtk-drag-hover) .fs-diagram-source {
  display: block;
}
.fs-diagram-object.draggable:active, .fs-diagram-object.draggable.jtk-drag-hover {
  z-index: 99;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.fs-diagram-connection-label {
  background: #fff;
  padding: 0 5px;
  z-index: 7;
  border-radius: 2px;
  font-size: 90%;
  color: #555;
}

.fs-diagram-connection.fs-diagram-clickable path,
.fs-diagram-connection-label.fs-diagram-clickable {
  cursor: pointer;
}

.fs-diagram-connection-tooltip-overlay {
  z-index: 9999;
}

.fs-diagram-connection-label-tooltip,
.fs-diagram-connection-tooltip {
  transform-origin: center top 0px;
  transform: scale(1);
  color: #fff;
  border-radius: 4px;
  max-width: 250px;
  padding: 6px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #707070;
  font-size: 80%;
  display: none;
  position: absolute;
  white-space: pre;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.fs-diagram-connection-label-tooltip.show,
.fs-diagram-connection-tooltip.show {
  display: block;
}
.fs-diagram-connection-label-tooltip:hover,
.fs-diagram-connection-tooltip:hover {
  display: block;
}

.fs-diagram-connection-tooltip {
  top: -13px;
}

.fs-diagram-connection-label-tooltip {
  top: 18px;
}

.fs-diagram-connection-label:hover .fs-diagram-connection-label-tooltip {
  display: block;
}

.field-container.cdk-drag-preview,
.fs-field-option.cdk-drag-preview {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  opacity: 0.45;
}
.field-container.cdk-drag-placeholder,
.fs-field-option.cdk-drag-placeholder {
  background: #F9F9F9;
}
.field-container.cdk-drag-placeholder > *,
.fs-field-option.cdk-drag-placeholder > * {
  visibility: hidden;
}
.field-container.cdk-drag-placeholder .option-container,
.fs-field-option.cdk-drag-placeholder .option-container {
  visibility: hidden;
}

fs-field-editor fs-label-field fs-label-message,
fs-field-renderer fs-label-field fs-label-message {
  padding-bottom: 1em;
  display: block;
}
fs-field-editor .field-container,
fs-field-renderer .field-container {
  width: 100%;
}
fs-field-editor .field-description,
fs-field-renderer .field-description {
  padding-bottom: 7px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75em;
}
fs-field-editor fs-label-field.multiline-message .field-message,
fs-field-renderer fs-label-field.multiline-message .field-message {
  position: relative !important;
  bottom: unset !important;
  padding-top: 7px;
  display: block;
  color: rgba(0, 0, 0, 0.54);
  white-space: pre-line;
}

.toolbar-field.cdk-drag-preview {
  background: #ccc;
  color: #fff;
  border-radius: 3px;
}

.fs-field-toolbar-menu-hidden {
  display: none;
}

fs-sidenav ul {
  padding: 0;
  margin: 0;
}
fs-sidenav ul.level-1 {
  display: none;
}
fs-sidenav ul.level-1 a.item-content {
  font-size: 85%;
  padding-left: 48px;
}
fs-sidenav ul.level-2 a.item-content {
  padding-left: 96px;
}
fs-sidenav li {
  display: block;
}
fs-sidenav li .item-content.clickable {
  cursor: pointer;
}
fs-sidenav li.active-item ul {
  display: block;
}
fs-sidenav li a.item-content.active-item-content {
  background: rgba(227, 242, 253, 0.35);
  border-left: 4px solid rgba(255, 255, 255, 0.7);
}
fs-sidenav li a.item-content.active-item-content .open {
  display: inline-block;
}
fs-sidenav li a.item-content.active-item-content .closed {
  display: none;
}
fs-sidenav li.item-menu .item-content {
  cursor: pointer;
}
fs-sidenav li .item-icon {
  margin-right: 5px;
}
fs-sidenav li .item-content {
  text-decoration: none;
  padding: 13px 14px;
  color: #fff;
  border-left: 4px solid transparent;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  align-items: center;
}
fs-sidenav li .item-content .label {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
fs-sidenav li .item-content .open {
  display: none;
}
fs-sidenav li .item-labels {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
fs-sidenav li .item-labels .superlabel,
fs-sidenav li .item-labels .sublabel {
  padding-bottom: 4px;
  font-size: 85%;
}
fs-sidenav li mat-icon {
  color: #fff;
}
fs-sidenav li .item-image {
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
}
fs-sidenav li .item-actions {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
fs-sidenav li .item-actions .item-action {
  margin: -8px -8px -8px 0;
}

.fs-sidenav-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
}
.fs-sidenav-menu .fs-sidenav-menu-content {
  overflow: auto;
  flex: 1;
  margin: 0 -24px;
  padding: 0 24px;
}
.fs-sidenav-menu .fs-sidenav-menu-content:after, .fs-sidenav-menu .fs-sidenav-menu-content:before {
  margin-left: -24px;
  margin-right: -24px;
}
.fs-sidenav-menu .fs-sidenav-menu-actions {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -24px -24px -24px;
}

.fs-sidenav-menu-container {
  background: #fff;
  border-radius: 2px;
  max-width: 400px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin: 10px;
  box-sizing: content-box;
}

.fs-sidenav-menu-actions {
  padding: 8px;
}

.fs-sidenav-menu-items {
  display: flex;
  flex-direction: column;
  padding: 8px 15px;
}
.fs-sidenav-menu-items .fs-sidenav-menu-item {
  margin: 0 -15px;
  line-height: 48px;
  width: auto;
  max-width: none;
  display: block;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  position: relative;
  background: #fff;
}
.fs-sidenav-menu-items .fs-sidenav-menu-item:hover:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

@media only screen and (max-width: 600px) {
  .fs-sidenav-menu-overlay-pane {
    transform: none !important;
    position: absolute !important;
    top: unset !important;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .fs-sidenav-menu-container {
    width: 100%;
    border-radius: 0;
    max-width: unset;
    margin: 0;
  }

  .fs-sidenav-menu-actions {
    display: flex;
    flex-direction: column;
  }
  .fs-sidenav-menu-actions button {
    width: 100%;
  }
}
body.fs-sidenav-menu-open::-webkit-scrollbar {
  background: transparent;
}

.fs-chip {
  user-select: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 0px 12px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  height: 30px;
  background-color: #e0e0e0;
  overflow: hidden;
}
.fs-chip.imaged {
  overflow: visible;
  padding-left: 0;
}
.fs-chip.imaged.outlined .image {
  margin-left: -2px;
}
.fs-chip.removable, .fs-chip.selected {
  padding-right: 3px;
}
.fs-chip.selectable {
  cursor: pointer;
}
.fs-chip.outlined {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}
.fs-chip .image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -1px;
  margin-right: 8px;
}
.fs-chip .fs-chip-content {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fs-chip .selected-check {
  margin: 0 5px;
  display: flex;
}
.fs-chip .remove {
  display: flex;
  margin-left: 5px;
  cursor: pointer;
}
.fs-chip .remove mat-icon {
  transform: scale(0.9);
  color: #929292;
  height: 23px;
}
.fs-chip.small {
  padding: 0 8px;
  font-size: 85%;
  height: 22px;
  line-height: normal;
}
.fs-chip.small .image {
  height: 22px;
  width: 22px;
  margin-right: 5px;
}
.fs-chip.small.imaged {
  padding-left: 0;
}
.fs-chip.small .remove {
  margin-left: 3px;
}
.fs-chip.small .selected-check {
  margin: 0 2px 0 0;
}
.fs-chip.small .selected-check mat-icon {
  transform: scale(0.7);
}
.fs-chip.small.removable, .fs-chip.small.selected {
  padding-right: 0;
}
.fs-chip.tiny {
  padding: 0 6px;
  height: 18px;
  line-height: normal;
}
.fs-chip.tiny .fs-chip-content {
  font-size: 75%;
}
.fs-chip.tiny .image {
  height: 18px;
  width: 18px;
  margin-right: 3px;
}
.fs-chip.tiny .remove {
  margin-left: 2px;
}
.fs-chip.tiny .selected-check {
  margin: 0 1px 0 0;
}
.fs-chip.tiny.imaged {
  padding-left: 0;
}
.fs-chip.tiny.removable, .fs-chip.tiny.selected {
  padding-right: 0px;
}
.fs-chip.tiny mat-icon {
  transform: scale(0.7);
}
.fs-chip.micro {
  padding: 0 5px;
  height: 16px;
  line-height: normal;
}
.fs-chip.micro .fs-chip-content {
  font-size: 65%;
}
.fs-chip.micro .image {
  height: 100%;
  width: 16px;
  margin-right: 2px;
}
.fs-chip.micro .remove {
  margin-left: 1px;
}
.fs-chip.micro .selected-check {
  margin: 0 1px 0 0;
}
.fs-chip.micro.imaged {
  padding-left: 0;
}
.fs-chip.micro.removable, .fs-chip.micro.selected {
  padding-right: 0px;
}
.fs-chip.micro mat-icon {
  transform: scale(0.65);
  width: 20px;
}

.fs-chips {
  display: flex;
  flex-wrap: wrap;
}
.fs-chips.has-chips {
  margin-top: -5px;
}
.fs-chips .fs-chip {
  margin-right: 5px;
  margin-top: 5px;
}

.mat-tab-nav-bar {
  overflow-x: auto !important;
  overflow-y: visible !important;
  display: flex;
  white-space: nowrap;
  width: 100%;
}

.cdk-overlay-pane.fs-dialog-overlay-pane {
  min-width: 400px;
}
.cdk-overlay-pane.fs-dialog-overlay-pane .mat-dialog-content {
  position: relative;
  z-index: 1;
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 19px, 100% 19px, 100% 7px, 100% 7px;
  /*
    iOS half pixel horizontal fix
  */
  border-bottom: 1px solid #fff;
}

fs-dialog .mat-dialog-actions .mat-button,
fs-dialog .mat-dialog-actions .mat-button + .mat-button {
  margin-left: 0;
  margin-right: 8px;
}
fs-dialog.action-placement-bottom .mat-dialog-content {
  flex: 1;
}

.cdk-overlay-backdrop.fs-dialog-overlay-backdrop.fs-dialog-mode-full {
  background: white;
}

.cdk-overlay-pane.fs-dialog-overlay-pane .mat-dialog-container {
  padding-bottom: max(env(safe-area-inset-bottom), 24px);
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full:not(.fs-dialog-mode-float):not(.fs-dialog-mode-peek) .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek:not(.fs-dialog-mode-float):not(.fs-dialog-mode-peek) .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float:not(.fs-dialog-mode-float):not(.fs-dialog-mode-peek) .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom:not(.fs-dialog-mode-float):not(.fs-dialog-mode-peek) .mat-dialog-container {
  border-radius: 0;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full:not(.fs-dialog-mode-float), .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek:not(.fs-dialog-mode-float), .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float:not(.fs-dialog-mode-float), .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom:not(.fs-dialog-mode-float) {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute !important;
  max-width: none !important;
  width: auto !important;
  min-width: initial !important;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full:not(.fs-dialog-mode-float) fs-dialog, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek:not(.fs-dialog-mode-float) fs-dialog, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float:not(.fs-dialog-mode-float) fs-dialog, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom:not(.fs-dialog-mode-float) fs-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full:not(.fs-dialog-mode-float) fs-dialog .mat-dialog-content, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek:not(.fs-dialog-mode-float) fs-dialog .mat-dialog-content, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float:not(.fs-dialog-mode-float) fs-dialog .mat-dialog-content, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom:not(.fs-dialog-mode-float) fs-dialog .mat-dialog-content {
  max-height: initial;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full:not(.fs-dialog-mode-float) .mat-dialog-container form, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek:not(.fs-dialog-mode-float) .mat-dialog-container form, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float:not(.fs-dialog-mode-float) .mat-dialog-container form, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom:not(.fs-dialog-mode-float) .mat-dialog-container form {
  display: inline;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full.fs-dialog-mode-bottom, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek.fs-dialog-mode-bottom, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float.fs-dialog-mode-bottom, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom.fs-dialog-mode-bottom {
  top: initial;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full.fs-dialog-mode-bottom .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek.fs-dialog-mode-bottom .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float.fs-dialog-mode-bottom .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom.fs-dialog-mode-bottom .mat-dialog-container {
  height: unset;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full.fs-dialog-mode-peek, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek.fs-dialog-mode-peek, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float.fs-dialog-mode-peek, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom.fs-dialog-mode-peek {
  padding-top: 25px;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full.fs-dialog-mode-full, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full.fs-dialog-mode-peek, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek.fs-dialog-mode-full, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek.fs-dialog-mode-peek, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float.fs-dialog-mode-full, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float.fs-dialog-mode-peek, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom.fs-dialog-mode-full, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom.fs-dialog-mode-peek {
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full.fs-dialog-mode-full .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full.fs-dialog-mode-peek .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek.fs-dialog-mode-full .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek.fs-dialog-mode-peek .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float.fs-dialog-mode-full .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float.fs-dialog-mode-peek .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom.fs-dialog-mode-full .mat-dialog-container, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom.fs-dialog-mode-peek .mat-dialog-container {
  box-shadow: none;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions {
  flex-direction: column;
  flex-wrap: nowrap;
  display: flex;
  flex: none;
  min-height: auto;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions .mat-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions .mat-raised-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions .mat-flat-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions .mat-stroked-button, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions .mat-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions .mat-raised-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions .mat-flat-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions .mat-stroked-button, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions .mat-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions .mat-raised-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions .mat-flat-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions .mat-stroked-button, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions .mat-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions .mat-raised-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions .mat-flat-button,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions .mat-stroked-button {
  width: 100%;
  margin: 0 0 4px 0;
}
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions .mat-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions .mat-raised-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions .mat-flat-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-full .mat-dialog-actions .mat-stroked-button:last-child, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions .mat-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions .mat-raised-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions .mat-flat-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-peek .mat-dialog-actions .mat-stroked-button:last-child, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions .mat-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions .mat-raised-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions .mat-flat-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-float .mat-dialog-actions .mat-stroked-button:last-child, .cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions .mat-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions .mat-raised-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions .mat-flat-button:last-child,
.cdk-overlay-pane.fs-dialog-overlay-pane.fs-dialog-mode-bottom .mat-dialog-actions .mat-stroked-button:last-child {
  margin-bottom: 0;
}

.fs-form-label-required:after {
  content: "*";
  white-space: pre-wrap;
}

.fs-form .fs-form-wrapper.ng-invalid.ng-dirty .fs-form-label,
.fs-form .ng-invalid.ng-dirty:not(form) .fs-form-label {
  color: #f44336;
}

.fs-form .ng-invalid.ng-dirty:not(form) .mat-form-field-underline {
  background-color: #f44336;
}

.fs-form .fs-form-error {
  color: #f44336;
}

.fs-form .fs-form-message {
  margin-top: 0.54166667em;
}

.fs-form .mat-placeholder-required {
  display: none;
}

.fs-form button.submit-success,
.fs-form button.submit-error,
.fs-form button.submit-process {
  transition: none;
  cursor: wait;
  color: transparent !important;
  pointer-events: none;
}
.fs-form button.submit-success svg,
.fs-form button.submit-error svg,
.fs-form button.submit-process svg {
  height: 22px;
  vertical-align: middle;
  width: 22px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadein 1s;
}

.fs-form .mat-button svg.svg-icon-process {
  stroke: #a3a3a3;
}
.fs-form .mat-button svg.svg-icon-error path,
.fs-form .mat-button svg.svg-icon-success path {
  fill: #a3a3a3;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
mat-select[fsSelect] img,
.mat-select-panel.fs-select-panel img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.mat-select-panel.fs-select-panel .mat-option-text {
  align-items: center;
  display: flex;
}
.mat-select-panel.fs-select-panel img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}

mat-select[fsSelect] .mat-select-value-text img {
  position: absolute;
  top: -6px;
}
mat-select[fsSelect] .mat-select-value-text img + * {
  margin-left: 28px;
}

.fs-pin-panel {
  width: 100%;
  background: #fff;
  border-top: 1px solid #efefef;
  bottom: 0;
  z-index: 999;
  position: sticky;
}
.fs-pin-panel.fs-pin-panel-fixed {
  position: fixed;
}

.fs-pin-anchor {
  visibility: hidden;
}

.fs-delimit > *:not(:last-child):after {
  content: ", ";
}

.fs-gallery-preview-open {
  overflow: hidden;
}

.fs-gallery-no-results {
  text-align: center;
  color: #999;
  padding: 10px 0;
}

.mat-select.fs-select-button {
  flex-direction: row;
  align-items: center;
  width: auto;
  transition: none !important;
}
.mat-select.fs-select-button .mat-select-placeholder {
  margin-right: 10px;
  color: inherit;
}
.mat-select.fs-select-button .mat-select-trigger {
  display: flex;
  align-items: center;
  height: auto;
}
.mat-select.fs-select-button:not(.mat-basic) .mat-select-arrow,
.mat-select.fs-select-button:not(.mat-basic) .mat-select-value {
  color: #fff;
}
.mat-select.fs-select-button:not(.mat-basic) .mat-select-value {
  display: inline;
}
.mat-select.fs-select-button .mat-select-value {
  max-width: initial;
}
.mat-select.fs-select-button .mat-select-value-text {
  max-width: 150px;
  display: block;
  margin-right: 10px;
}
.mat-select.fs-select-button.mat-select-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow: none;
  pointer-events: none;
}
.mat-select.fs-select-button.mat-select-disabled .mat-select-value-text {
  color: rgba(0, 0, 0, 0.26);
}
.mat-select.fs-select-button.mat-select-disabled .mat-select-arrow {
  border-top-color: rgba(0, 0, 0, 0.26);
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #383838;
  margin-bottom: 10px;
  margin-top: 20px;
}

h2 {
  font-style: normal;
  font-size: 18px;
  color: #383838;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: normal;
}

h3 {
  font-style: normal;
  font-size: 16px;
  color: #383838;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: normal;
}

h1 .subheading, h2 .subheading, h3 .subheading {
  font-size: 75%;
  font-weight: normal;
  color: #565656;
  line-height: normal;
}

h1 + .subheading,
.subheading-1 {
  font-size: 85%;
}

.subheading-2,
h2 + .subheading {
  font-size: 85%;
}

.subheading-3,
h3 + .subheading {
  font-size: 85%;
}

.subheading-1,
.subheading-2,
.subheading-3,
h1 + .subheading,
h2 + .subheading,
h3 + .subheading {
  font-style: normal;
  font-weight: normal;
  color: #676767;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 5px 0;
}
.subheading-1:not(.subheading-1):not(.subheading-2):not(.subheading-3),
.subheading-2:not(.subheading-1):not(.subheading-2):not(.subheading-3),
.subheading-3:not(.subheading-1):not(.subheading-2):not(.subheading-3),
h1 + .subheading:not(.subheading-1):not(.subheading-2):not(.subheading-3),
h2 + .subheading:not(.subheading-1):not(.subheading-2):not(.subheading-3),
h3 + .subheading:not(.subheading-1):not(.subheading-2):not(.subheading-3) {
  margin-top: -5px;
}
.subheading-1 .material-icons,
.subheading-2 .material-icons,
.subheading-3 .material-icons,
h1 + .subheading .material-icons,
h2 + .subheading .material-icons,
h3 + .subheading .material-icons {
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.h1-container,
.h2-container {
  margin-top: -20px !important;
}
.h1-container .fs-list-container.has-heading,
.h2-container .fs-list-container.has-heading {
  margin-top: 20px;
}

.form-field-lineless:not(.mat-focused):hover .mat-form-field-underline {
  border-top: 1px dashed rgba(var(--brand-primary-color-rgb), 0.3);
}

.form-field-multiline-subscript .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
.form-field-multiline-subscript .mat-form-field-wrapper .mat-form-field-underline {
  position: initial;
  display: block;
  margin-top: -1px;
}
.form-field-multiline-subscript .mat-form-field-wrapper .mat-form-field-subscript-wrapper,
.form-field-multiline-subscript .mat-form-field-wrapper .mat-form-field-ripple {
  position: initial;
  display: table;
}
.form-field-multiline-subscript .mat-form-field-wrapper .mat-form-field-subscript-wrapper mat-hint:not(:empty),
.form-field-multiline-subscript .mat-form-field-wrapper .mat-form-field-subscript-wrapper .fs-form-error,
.form-field-multiline-subscript .mat-form-field-wrapper .mat-form-field-ripple mat-hint:not(:empty),
.form-field-multiline-subscript .mat-form-field-wrapper .mat-form-field-ripple .fs-form-error {
  margin-bottom: 1em;
  display: block;
}
.form-field-multiline-subscript .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  min-height: calc(1em + 1px);
}

.mat-form-field.mat-form-field-appearance-legacy.form-field-padless-top .mat-form-field-infix, .mat-form-field.mat-form-field-appearance-legacy.form-field-padless .mat-form-field-infix {
  border-top: 0;
}
.mat-form-field.mat-form-field-appearance-legacy.form-field-padless-bottom .mat-form-field-underline, .mat-form-field.mat-form-field-appearance-legacy.form-field-padless .mat-form-field-underline {
  bottom: 0;
}
.mat-form-field.mat-form-field-appearance-legacy.form-field-padless-bottom .mat-form-field-wrapper, .mat-form-field.mat-form-field-appearance-legacy.form-field-padless .mat-form-field-wrapper {
  padding-bottom: 0;
}
.mat-form-field.mat-form-field-appearance-legacy.form-field-padless-bottom .mat-form-field-wrapper .mat-form-field-subscript-wrapper, .mat-form-field.mat-form-field-appearance-legacy.form-field-padless .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  position: absolute !important;
  top: unset;
  margin-top: 0;
}
.mat-form-field.mat-form-field-appearance-standard.form-field-padless-top .mat-form-field-flex, .mat-form-field.mat-form-field-appearance-standard.form-field-padless .mat-form-field-flex, .mat-form-field.mat-form-field-appearance-outline.form-field-padless-top .mat-form-field-flex, .mat-form-field.mat-form-field-appearance-outline.form-field-padless .mat-form-field-flex {
  padding-top: 0;
}
.mat-form-field.mat-form-field-appearance-standard.form-field-padless-top .mat-form-field-infix, .mat-form-field.mat-form-field-appearance-standard.form-field-padless .mat-form-field-infix, .mat-form-field.mat-form-field-appearance-outline.form-field-padless-top .mat-form-field-infix, .mat-form-field.mat-form-field-appearance-outline.form-field-padless .mat-form-field-infix {
  border-top: 0;
}
.mat-form-field.mat-form-field-appearance-standard.form-field-padless-top .mat-form-field-outline, .mat-form-field.mat-form-field-appearance-standard.form-field-padless .mat-form-field-outline, .mat-form-field.mat-form-field-appearance-outline.form-field-padless-top .mat-form-field-outline, .mat-form-field.mat-form-field-appearance-outline.form-field-padless .mat-form-field-outline {
  top: 0;
}
.mat-form-field.mat-form-field-appearance-standard.form-field-padless-bottom .mat-form-field-underline, .mat-form-field.mat-form-field-appearance-standard.form-field-padless .mat-form-field-underline, .mat-form-field.mat-form-field-appearance-outline.form-field-padless-bottom .mat-form-field-underline, .mat-form-field.mat-form-field-appearance-outline.form-field-padless .mat-form-field-underline {
  bottom: 0;
}
.mat-form-field.mat-form-field-appearance-standard.form-field-padless-bottom .mat-form-field-wrapper, .mat-form-field.mat-form-field-appearance-standard.form-field-padless .mat-form-field-wrapper, .mat-form-field.mat-form-field-appearance-outline.form-field-padless-bottom .mat-form-field-wrapper, .mat-form-field.mat-form-field-appearance-outline.form-field-padless .mat-form-field-wrapper {
  padding-bottom: 0;
}
.mat-form-field.mat-form-field-appearance-standard.form-field-padless-bottom .mat-form-field-wrapper .mat-form-field-subscript-wrapper, .mat-form-field.mat-form-field-appearance-standard.form-field-padless .mat-form-field-wrapper .mat-form-field-subscript-wrapper, .mat-form-field.mat-form-field-appearance-outline.form-field-padless-bottom .mat-form-field-wrapper .mat-form-field-subscript-wrapper, .mat-form-field.mat-form-field-appearance-outline.form-field-padless .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  position: absolute !important;
  top: unset;
  margin-top: 0;
}

.form-field-grow .mat-form-field-infix {
  width: fit-content;
}
.form-field-grow .mat-form-field-infix .mat-select-value {
  max-width: 100%;
  width: auto;
}

.form-field-lineless:not(.mat-focused) .mat-form-field-underline {
  background-color: transparent;
}
.form-field-lineless:not(.mat-focused) input {
  text-overflow: ellipsis;
}

.full-width {
  width: 100% !important;
}
.full-width.mat-form-field .mat-form-field-infix {
  width: auto;
}

.fs-row,
.fs-row-form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.fs-row.fs-row-gap-large,
.fs-row-form-field.fs-row-gap-large {
  column-gap: 40px;
}
.fs-row.fs-row-gap-small,
.fs-row-form-field.fs-row-gap-small {
  column-gap: 10px;
}
.fs-row.fs-row-gap-tiny,
.fs-row-form-field.fs-row-gap-tiny {
  column-gap: 5px;
}
.fs-row.fs-row-align-start,
.fs-row-form-field.fs-row-align-start {
  align-items: flex-start;
}
.fs-row.fs-row-align-end,
.fs-row-form-field.fs-row-align-end {
  align-items: flex-end;
}
.fs-row.fs-row-align-baseline,
.fs-row-form-field.fs-row-align-baseline {
  align-items: baseline;
}
.fs-row.fs-row-wrap,
.fs-row-form-field.fs-row-wrap {
  flex-wrap: wrap;
}
.fs-row.form-field, .fs-row.fs-row-form-field,
.fs-row-form-field.form-field,
.fs-row-form-field.fs-row-form-field {
  column-gap: 10px;
}
.fs-row.fs-row-form-field .mat-form-field-infix, .fs-row.form-field .mat-form-field-infix,
.fs-row-form-field.fs-row-form-field .mat-form-field-infix,
.fs-row-form-field.form-field .mat-form-field-infix {
  width: auto;
}
.fs-row.fs-row-form-field > *, .fs-row.form-field > *,
.fs-row-form-field.fs-row-form-field > *,
.fs-row-form-field.form-field > * {
  width: 100%;
}

@media (max-width: 599px) {
  .fs-row.form-field,
.fs-row-form-field {
    flex-direction: column;
  }
}
.overflow-shadow {
  position: relative;
  z-index: 1;
  background: #FFF no-repeat;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(213, 213, 213, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.1068802521) 0%, rgba(213, 213, 213, 0) 100%);
  background-position: 0 0, 0 100%;
  background-size: 100% 5px;
}
.overflow-shadow:before, .overflow-shadow:after {
  content: "";
  position: relative;
  z-index: -1;
  display: block;
  height: 10px;
  background: linear-gradient(to bottom, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
}
.overflow-shadow:before {
  margin-bottom: -10px;
}
.overflow-shadow:after {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(70%, #FFF), to(#FFF));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
}

.small, small {
  color: #676767;
  font-size: 85%;
}

.large {
  font-size: 115%;
}

.error {
  color: #CC0001;
}

fs-file fs-file-dragover-message .dragover-message {
  z-index: 999;
  display: none;
}
fs-file fs-file-dragover-message .dragover-message .content {
  background-color: var(--brand-primary-color);
}
fs-file fs-file-dragover-message .dragover-message .bounce {
  color: var(--brand-primary-color);
}
fs-file.dragover fs-file-dragover-message .dragover-message {
  display: flex !important;
}

fs-file-picker.dragover .wrap:after {
  border-color: var(--brand-primary-color);
}

fs-file-image-picker .wrap:not(.exists) .image-preview {
  border-color: var(--brand-primary-color) !important;
}

fs-file-previews {
  margin-top: -10px;
  display: block;
}
fs-file-previews .queue {
  position: relative;
  display: block;
}
fs-file-previews fs-file-preview {
  margin-right: 10px;
  margin-top: 10px;
}
fs-file-previews fs-file-preview:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 550px) {
  fs-file-preview .actions {
    display: block;
    background: none;
  }
}
.fs-selection-pane {
  max-width: 100% !important;
}
@media all and (max-width: 400px) {
  .fs-selection-pane .mat-dialog-content {
    padding: 10px 5px !important;
    margin: 0 !important;
  }
}
.fs-selection-pane .mat-dialog-container {
  padding: 10px;
  border-radius: 0;
}
.fs-selection-pane .mat-dialog-content {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin: 0 -10px;
  padding: 0 20px;
  height: 50px;
  overflow-y: hidden;
}
.fs-selection-pane .mat-dialog-content .selected {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
}
.fs-selection-pane .mat-dialog-content .selected .select-all-link {
  cursor: pointer;
  font-size: 95%;
}
.fs-selection-pane .actions {
  display: flex;
  margin-left: 20px;
  flex: 1 0 auto;
}
.fs-selection-pane .actions .mat-select {
  width: auto;
}
@media only screen and (max-width: 400px) {
  .fs-selection-pane .actions .cancel-btn {
    padding: 0;
  }
}
.fs-selection-pane .action {
  margin-left: 15px;
}
.fs-selection-pane .selected-badge {
  margin-right: 3px;
  background: var(--brand-primary-color);
  color: #fff;
  display: inline-block;
  width: 28px;
  text-align: center;
  border-radius: 50%;
  line-height: 28px;
}

[hidden] {
  display: none !important;
}

fs-drawer .drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 75;
  background-color: #fff;
  min-width: 350px;
}
fs-drawer .left {
  left: 0;
}
fs-drawer .left .drawer-container > *:first-child {
  margin-left: 0 !important;
  flex-direction: row-reverse;
}
fs-drawer .left .drawer-actions {
  right: 0;
}
fs-drawer .right {
  right: 0;
}
fs-drawer .right .drawer-actions {
  left: 0;
}
fs-drawer .drawer-container {
  height: 100%;
}
fs-drawer .drawer-container > *:first-child {
  margin-left: 45px;
  display: flex;
  align-content: space-between;
  height: 100%;
}
fs-drawer .drawer-container .side {
  display: flex;
}
fs-drawer .drawer-container .side-container {
  overflow-y: auto;
}
fs-drawer .drawer-container .side-container .side-content {
  padding: 15px;
  min-width: 200px;
  box-sizing: border-box;
}
fs-drawer .drawer-container .content-container {
  width: 100%;
  overflow-y: auto;
}
fs-drawer .drawer-container .content-container .content {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  min-width: 200px;
}
fs-drawer .drawer-actions {
  position: absolute;
  width: 45px;
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  white-space: nowrap;
  user-select: none;
}
fs-drawer .drawer-actions .action-btn {
  position: relative;
  margin-bottom: 5px;
  z-index: 5;
  text-align: center;
}
fs-drawer .drawer-actions .action-btn > button {
  border: none;
  outline: none;
}
fs-drawer .drawer-actions .material-icons {
  color: #4c4c4c;
}
fs-drawer .drawer-actions button.active .material-icons {
  color: var(--brand-primary-color);
}
fs-drawer .drawer-actions:hover .drag-icon {
  opacity: 1;
}
fs-drawer .drawer-actions:hover .drag-ripple {
  background: #F9F9F9;
}
fs-drawer .drawer-actions:hover .actions-container:hover ~ * {
  display: none;
}
fs-drawer .resize-icon {
  display: flex;
  width: 100%;
  border-width: 0 1px;
  box-sizing: border-box;
  user-select: none;
  z-index: 1;
  cursor: col-resize;
}
fs-drawer .resize-icon span {
  width: 100%;
}
fs-drawer .resize-icon .material-icons {
  color: #B3B3B3;
}
fs-drawer .resize-side {
  display: flex;
  align-items: center;
  width: 45px;
  border-left: 1px solid #dcdcdc;
  background: transparent;
  transition: 200ms;
  user-select: none;
}
fs-drawer .resize-side .resize-icon {
  opacity: 0;
  transition: 200ms;
}
fs-drawer .resize-side:hover {
  background: #F9F9F9;
}
fs-drawer .resize-side:hover .resize-icon {
  opacity: 1;
}

.cdk-drag-placeholder {
  background-color: var(--brand-primary-color) !important;
  opacity: 0.2;
}

.toolbar-field.cdk-drag-preview {
  background: var(--brand-primary-color);
  white-space: nowrap;
  height: auto !important;
  width: auto !important;
}
.toolbar-field.cdk-drag-preview .label {
  margin-right: 15px;
}
.toolbar-field.cdk-drag-preview .add {
  display: inline;
}
.toolbar-field.cdk-drag-preview ::ng-deep .mat-button-ripple {
  display: none;
}

.toolbar-button {
  color: white;
}

.fs-tile.theme-dark .wrapper {
  background: var(--brand-primary-color);
}

.mat-form-field.mat-form-field-invalid.ng-valid .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-form-field-invalid.ng-valid .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}

.fs-form button.mat-button svg.svg-icon-process, .fs-form button.mat-raised-button:not([color=primary]) svg.svg-icon-process, .fs-form button.mat-flat-button:not([color=primary]) svg.svg-icon-process {
  stroke: var(--brand-primary-color);
}
.fs-form button.mat-button svg.svg-icon-error path,
.fs-form button.mat-button svg.svg-icon-success path, .fs-form button.mat-raised-button:not([color=primary]) svg.svg-icon-error path,
.fs-form button.mat-raised-button:not([color=primary]) svg.svg-icon-success path, .fs-form button.mat-flat-button:not([color=primary]) svg.svg-icon-error path,
.fs-form button.mat-flat-button:not([color=primary]) svg.svg-icon-success path {
  fill: var(--brand-primary-color);
}
.fs-form button.mat-raised-button[color=primary] svg.svg-icon-process, .fs-form button.mat-flat-button[color=primary] svg.svg-icon-process {
  stroke: #fff;
}
.fs-form button.mat-raised-button[color=primary] svg.svg-icon-error path,
.fs-form button.mat-raised-button[color=primary] svg.svg-icon-success path, .fs-form button.mat-flat-button[color=primary] svg.svg-icon-error path,
.fs-form button.mat-flat-button[color=primary] svg.svg-icon-success path {
  fill: #fff;
}

body.block-selection {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

fs-tree .droppable-area {
  display: block;
  position: fixed;
  z-index: 99999;
  background: var(--brand-primary-color);
  height: 10px;
  border-radius: 2px;
}
fs-tree .draggable-item .container .content {
  cursor: copy !important;
}
fs-tree .no-drop .container .content {
  cursor: no-drop !important;
}
fs-tree .mat-checkbox {
  margin-right: 10px;
}
fs-tree .blocked {
  opacity: 0.5;
}
fs-tree .node {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 2px;
}
fs-tree .node .container {
  display: flex;
  align-items: center;
}
fs-tree .node .container .fs-tree-draggable-target {
  cursor: grab;
  margin-right: 10px;
  display: none;
}
fs-tree .node .container .fs-tree-draggable-target.no-drag {
  cursor: default !important;
}
fs-tree .node .container .fs-tree-draggable-target.draggable {
  display: inline;
}
fs-tree .node .container .content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
fs-tree .node .container .content.clickable {
  pointer-events: all;
  cursor: pointer;
}
fs-tree .mat-tree-node {
  overflow: visible;
  flex-direction: column;
}
fs-tree .mat-tree-node.disabled-node .container {
  opacity: 0.5;
}
fs-tree .mat-tree-node.draggable-elem > .node > .container {
  opacity: 0.5;
}
fs-tree .mat-tree-node.drag-over .node {
  background-color: var(--brand-primary-color);
}
fs-tree .mat-tree-node .draggable-item {
  position: fixed;
  z-index: 999999;
  background: #fff;
  border-radius: 4px;
  opacity: 0.95;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  min-height: 40px;
  padding: 0 15px;
}
fs-tree .mat-tree-node .draggable-item button, fs-tree .mat-tree-node .draggable-item .checklist-leaf-node {
  display: none;
}

fs-html-editor.focused .bottom-line {
  border-color: var(--brand-primary-color);
}

fs-autocomplete-chips .none-placeholder:hover {
  border-color: var(--brand-primary-color) !important;
}

.fs-popover {
  cursor: pointer;
}
.fs-popover.fs-popover-component {
  display: inline-flex;
  position: relative;
}
.fs-popover.fs-popover-component.fs-popover-enabled.indication .popover-container {
  text-decoration-color: var(--brand-primary-color) !important;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: #ccc;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
}
.fs-popover.fs-popover-enabled.indication:not(.fs-popover-component) {
  text-decoration-color: var(--brand-primary-color) !important;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: #ccc;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
}

.fs-popover-wrapper .loading-spinner svg {
  stroke: var(--brand-primary-color) !important;
}

.fs-attribute-tag .fs-chip {
  font-size: 13px;
  min-height: auto !important;
  height: auto !important;
  padding: 3px 10px !important;
}

fs-badge {
  display: flex;
}

fs-drawer .drawer-container .content-container .content {
  padding-left: 8px;
}
fs-drawer .drawer-container .side-container .side-content {
  min-width: unset;
}

fs-list .fs-list-row-group {
  background-color: rgba(var(--brand-primary-color-rgb), 0.08);
}
fs-list .fs-list-row-group:hover td {
  background-color: none !important;
}

.editor-container .ql-toolbar {
  padding: 12px 0;
}

@media (max-width: 599px) {
  .toast-container .toast {
    width: 100%;
  }
}
@media (max-width: 599px) {
  .fs-dialog-mobile-mode-full {
    margin-top: 0 !important;
  }
}
@font-face {
  font-family: "San Francisco Pro";
  src: local("San Francisco Pro Display Regular"), local("San-Francisco-Pro-Display-Regular"), url("/assets/fonts/SFProDisplay-Regular.woff2") format("woff2"), url("/assets/fonts/SFProDisplay-Regular.woff") format("woff"), url("/assets/fonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "San Francisco Pro";
  src: local("San Francisco Pro Display Semibold"), local("San-Francisco-Pro-Display-Semibold"), url("/assets/fonts/SFProDisplay-Semibold.woff2") format("woff2"), url("/assets/fonts/SFProDisplay-Semibold.woff") format("woff"), url("/assets/fonts/SFProDisplay-Semibold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 15px;
  background: #f7f7f7;
  letter-spacing: 0.02em;
  font-weight: 400;
  font-style: normal;
}

a {
  cursor: pointer;
  cursor: hand;
  text-decoration: none;
}

a small {
  color: inherit;
}

small, .small {
  color: #6f6f6f;
  font-size: 13px;
}

.material-tooltip {
  white-space: pre-line;
}

.system-error-message .mat-dialog-content {
  white-space: pre-wrap;
}

.stacked-buttons button + button {
  margin-top: 5px;
}
.stacked-buttons button {
  width: 100%;
}

.multiline-tooltip {
  white-space: pre-line;
}

.mat-icon.back-icon {
  color: #949494;
  transform: scale(1.43);
  width: 30px;
}

.full-width-dialog {
  width: 100%;
  height: 100%;
  max-width: 100vw !important;
}

textarea.mat-input-element {
  overflow: hidden !important;
}

.form-field-bottom-margin {
  margin-bottom: 1.25em;
}

.multi-lines-area {
  white-space: pre-line;
}

.dialog-tab-content {
  width: 900px;
  max-width: 100%;
}

.body-card.body-signin .app-content,
.body-card.body-signup .app-content,
.body-card.body-password .app-content {
  max-width: 400px;
}

.body-card.body-share .app-content {
  flex-direction: row;
  align-items: center;
  max-width: none;
}

body.body-full-height .app-scroll-content,
body.body-full-height .container-side-nav,
body.body-full-height .mat-sidenav-container,
body.body-full-height .mat-sidenav-content {
  height: 100%;
}
body.body-full-height .mat-sidenav-content {
  display: flex;
  flex-direction: column;
}
body.body-full-height .mat-sidenav-content router-outlet + ng-component {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
  height: 100%;
}
body.body-full-height .mat-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.fs-tabs-sticky {
  width: 100vw !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 9 !important;
}

@media (max-width: 599px) {
  .body-sidenav .mat-sidenav-content {
    padding-top: 0 !important;
  }
  .body-sidenav .mat-toolbar {
    box-shadow: none !important;
  }
  .body-sidenav .mat-tab-nav-bar, .body-sidenav .mat-tab-header {
    border-bottom: 0 !important;
  }
}
.mat-sidenav-content {
  padding: 24px;
  box-sizing: border-box;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-button, .mat-raised-button,
.mat-flat-button, .mat-stroked-button,
.mat-tab-nav-bar,
.mat-tab-header {
  text-transform: uppercase;
}

.mat-tab-nav-bar,
.mat-tab-header {
  margin-bottom: 20px;
}

.mat-progress-bar svg {
  display: none;
}

.mat-option .mat-icon {
  margin-right: 8px;
}

.cdk-global-scrollblock {
  width: calc(100% - 6px);
}

.cdk-overlay-pane.fs-sidenav-menu-overlay-pane {
  max-height: 100%;
}

@media (max-width: 599px) {
  body:not(.body-card) .mat-card {
    box-shadow: none !important;
    border: none;
    padding: 0;
  }
  body:not(.body-card) .mat-card .mat-tab-group {
    margin-top: -12px;
  }
  body:not(.body-card) .mat-sidenav-content {
    padding: 12px;
  }
}
@media (max-width: 1023px) {
  .mat-toolbar-single-row {
    height: 56px !important;
  }

  /*
    Allows the fsFiter side drawer to overlay the toolbar
  */
  .mat-drawer-container,
.mat-drawer-content {
    z-index: auto !important;
  }
}
html, body {
  font-family: "San Francisco Pro", "Helvetica", sans-serif;
}

a {
  color: var(--brand-link-color);
}

body ::-webkit-scrollbar {
  width: var(--webkit-scrollbar-width);
}
body ::-webkit-scrollbar-thumb {
  background: var(--webkit-scrollbar-color);
}

app-root {
  display: block;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  /* iOS 11.0 */
  padding-bottom: calc(env(safe-area-inset-bottom));
  /* iOS 11.2  */
}

.app-container {
  height: 100%;
  overflow-x: hidden;
}
.app-container .app-content {
  margin: 0 auto;
  height: 100%;
}
.app-container > .ng-star-inserted {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fs-scroll {
  max-height: 100%;
}

.two-column {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
}

@media (max-width: 1023px) {
  .two-column {
    flex-direction: column;
  }
}
@media (min-width: 1023px) {
  .two-column > * {
    width: 50%;
  }
  .two-column > *:nth-child(n+2) {
    margin-left: 40px;
  }
}
.dark-logo,
.dark-logo:after {
  content: var(--brand-dark-logo, url("/assets/default-dark-image.png"));
}

.light-logo,
.light-logo:after {
  content: var(--brand-light-logo, url("/assets/default-light-image.png"));
}

svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}

@media (max-width: 599px) {
  body:not(.body-card) {
    background: #fff;
  }
  body:not(.body-card) .navbar {
    margin: 18px 0 !important;
  }
}
@media (max-width: 1023px) {
  .body-sidenav app-header {
    display: block;
  }
  .body-sidenav .navbar {
    display: none !important;
  }
  .body-sidenav .app-container {
    padding-top: 56px;
    box-sizing: border-box;
  }
}
@media (min-width: 1023px) {
  .body-sidenav app-header {
    display: none;
  }
}
.jumbo-heading {
  font-weight: bold;
  font-size: 36px;
  line-height: 51px;
  color: #383838;
  margin-bottom: 8px;
}

.app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 1 !important;
  transition: opacity 2s;
}
.app-loading img {
  width: 120px;
  height: 120px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-0 {
  margin-top: 0px;
}

.icon-button-tight {
  width: 30px !important;
  height: 30px !important;
  line-height: unset !important;
}

.gray-text {
  color: #757575;
}

@media print {
  @page {
    margin: 6.35mm;
  }
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  body,
body app-root,
body .app-container,
body .app-content {
    height: auto !important;
  }

  body {
    background-color: #fff;
    zoom: 80%;
  }
  body .app-container {
    padding: 0 !important;
    overflow: visible !important;
  }

  .mat-sidenav,
button,
fs-menu,
fs-filter .filter-search-container,
fs-list .fs-list-actions,
fs-list .fs-list-col-selection,
fs-list fs-list-pagination,
fs-chip .remove,
.mat-tab-list .mat-tab-link:not(.mat-tab-label-active),
.fs-nav-back,
navbar,
app-header,
nav,
.mat-select-arrow-wrapper,
mat-select[fsselectbutton] {
    display: none !important;
  }

  fs-chip.fs-chip.removable {
    padding: 0px 12px;
  }
  fs-chip.fs-chip.removable.small {
    padding: 0 9px;
  }

  fs-list .fs-list-col {
    padding: 3px !important;
  }

  .mat-sidenav-content {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-toolbar {
    background-color: #fff !important;
    box-shadow: none !important;
    color: inherit !important;
    padding: 0px !important;
  }

  .mat-card {
    box-shadow: none !important;
    padding: 0px !important;
  }

  a {
    color: inherit;
  }

  .avoid-page-break {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
}
.drawer-identifier-name {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  padding-top: 15px;
  margin-top: -15px;
  background: #fff;
  z-index: 15;
}
.drawer-identifier-name app-object-identifier {
  color: #6f6f6f;
}
.drawer-identifier-name .type-identifier {
  padding-bottom: 1.25em;
  align-items: center;
}
.drawer-identifier-name .type-identifier app-type-menu {
  margin-right: 5px;
}
.drawer-identifier-name .type-identifier app-object-identifier {
  margin-right: 5px;
}
.drawer-identifier-name .mat-form-field input {
  font-size: 19px;
}
.drawer-identifier-name .mat-form-field .mat-form-field-infix {
  width: 100%;
}

.drawer-header {
  position: sticky;
  top: 0;
  background: #fff;
}

.drawer-button-actions {
  position: sticky;
  bottom: 0;
  padding: 15px 0;
  background: #fff;
  z-index: 1;
}
.drawer-button-actions button {
  margin-right: 5px;
}