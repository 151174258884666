
.app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 1 !important;
  transition: opacity 2s;

  img {
    width: 120px;
    height: 120px;
  }
}
