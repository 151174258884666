
@media (max-width: $break-xsmall) {

  body:not(.body-card) {
    background: #fff;

    .navbar {
        margin: 18px 0 !important;
    }
  }
}

@media (max-width: $break-small) {

  .body-sidenav {
    app-header {
      display: block;
    }

    .navbar {
      display: none !important;
    }

    .app-container {
      padding-top: $header-height-small;
      box-sizing: border-box;
    }

    // Used if less then 16px iOS will zoom when element is clicked on
    // html, body,
    // button, select, html, textarea, input {
    //   font-size: 16px;
    // }
    }
}

@media (min-width: $break-small) {
  .body-sidenav {
    app-header {
      display: none;
    }
  }
}
