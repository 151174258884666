@import './variables';

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-0 {
  margin-top: 0px;
}

.icon-button-tight {
  width: 30px !important;
  height: 30px !important;
  line-height: unset !important;
}

.gray-text {
  color: $color-gray;
}
