
.drawer-identifier-name {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  padding-top: 15px;
  margin-top: -15px;
  background: #fff;
  z-index: 15;

  app-object-identifier {
    color: #6f6f6f;
  }

  .type-identifier {
    padding-bottom: 1.25em;
    align-items: center;

    app-type-menu {
      margin-right: 5px;
    }

    app-object-identifier {
      margin-right: 5px;
    }
  }

  .mat-form-field {
    input {
      font-size: 19px;
    }

    .mat-form-field-infix {
      width: 100%;
    }
  }
}

.drawer-header {
  position: sticky;
  top: 0;
  background: #fff;
}

.drawer-button-actions {
  position: sticky;
  bottom: 0;
  padding: 15px 0;
  background: #fff;
  z-index: 1;

  button {
    margin-right: 5px;
  }
}
