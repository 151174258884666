@use '@angular/material' as mat;
@import './palette';

$font-family: "San Francisco Pro", "Helvetica", sans-serif;

$break-xsmall: 599px;
$break-small: 1023px;
$break-medium: 1439px;
$break-large: 1919px;

$font-size-small: 12px;
$font-size-medium: 15px;
$font-size-default: 18px;

$color-gray: #757575;
$color-dark-gray: #383838;
$color-light-gray: #F2F2F2;

$color-error: #f44336;
$color-success: #92D050;

$header-height-small: 56px;
$heading-color: $color-dark-gray;

$palette-default: 500;

$body-font-size: 15px;
$body-line-height: 24px;
$body-1: mat.define-typography-level(15px, 24px, 500);

$brand-primary-color: var(--brand-primary-color);
$brand-nav-background-color: var(--brand-nav-background-color);
$brand-primary-color-rgb: var(--brand-primary-color-rgb);
$brand-background: var(--brand-background);
$brand-dark-logo: var(--brand-dark-logo, url('/assets/default-dark-image.png'));
$brand-light-logo: var(--brand-light-logo, url('/assets/default-light-image.png'));
$brand-link-color: var(--brand-link-color);
$webkit-scrollbar-width: var(--webkit-scrollbar-width);
$webkit-scrollbar-color: var(--webkit-scrollbar-color);

$palette-default: 500;

$primary-palette: mat.define-palette($palette, $palette-default);
$accent-palette: mat.define-palette($palette, $palette-default);
$warn-palette: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme($primary-palette, $accent-palette, $warn-palette);

$primary-color: mat.get-color-from-palette($primary-palette);
