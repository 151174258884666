@import "./variables.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 15px;
  background: #f7f7f7;
  letter-spacing: 0.02em;
  font-weight: 400;
  font-style: normal;
}

a {
  cursor: pointer;
  cursor: hand;
  text-decoration: none;
}

a small {
  color: inherit;
}

small, .small {
  color: #6f6f6f;
  font-size: 13px;
}

.material-tooltip {
  white-space: pre-line;
}

.system-error-message {
  .mat-dialog-content {
    white-space: pre-wrap;
  }
}

.stacked-buttons {
  button + button {
    margin-top: 5px;
  }

  button {
    width: 100%;
  }
}

.multiline-tooltip {
  white-space: pre-line;
}

.mat-icon.back-icon {
  color: #949494;
  transform: scale(1.43);
  width: 30px;
}

.full-width-dialog {
  width: 100%;
  height: 100%;
  max-width: 100vw !important;
}

textarea.mat-input-element {
  overflow: hidden !important;
}

.form-field-bottom-margin {
  margin-bottom: 1.25em;
}

.multi-lines-area {
  white-space: pre-line;
}

.dialog-tab-content {
  width: 900px;
  max-width: 100%
}
