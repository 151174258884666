.mat-sidenav-content {
  padding: 24px;
  box-sizing: border-box;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-button, .mat-raised-button,
.mat-flat-button, .mat-stroked-button,
.mat-tab-nav-bar,
.mat-tab-header {
  text-transform: uppercase;
}

.mat-tab-nav-bar,
.mat-tab-header {
  margin-bottom: 20px;
}

.mat-progress-bar {
  svg {
    display: none;
  }
}

.mat-option .mat-icon {
  margin-right: 8px;
}

// Used to retain FsScrollBar width
.cdk-global-scrollblock {
  width: calc(100% - 6px);
}

.cdk-overlay-pane.fs-sidenav-menu-overlay-pane {
  max-height: 100%;
}

@media (max-width: $break-xsmall) {
  body:not(.body-card) {

    .mat-card {
      box-shadow: none !important;
      border: none;
      padding: 0;

      .mat-tab-group {
        margin-top: -12px;
      }
    }

    .mat-sidenav-content {
      padding: 12px;
    }
  }
}

@media (max-width: $break-small) {

  .mat-toolbar-single-row {
    height: $header-height-small !important;
  }

  /*
    Allows the fsFiter side drawer to overlay the toolbar
  */
  .mat-drawer-container,
  .mat-drawer-content {
    z-index: auto !important;
  }
}
